
import { defineComponent } from 'vue'

// v-model on the custom component
// CHECK: (https://v3.vuejs.org/guide/migration/v-model.html#_3-x-syntax)

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: Boolean
  },
  setup(props, ctx) {
    const updateInput = () => {
      ctx.emit('update:modelValue', !props.modelValue)
    }
    return {
      updateInput
    }
  }
})
