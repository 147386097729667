
import { defineComponent, onMounted } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInRadiusSiteConfig from '@/composable/useGridInRadiusSiteConfig'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewRadiusClientForm from '@/components/Form/RadiusSiteConfigView/NewRadiusClientForm.vue'
import DynamicVlanForm from '@/components/Form/RadiusSiteConfigView/DynamicVlanForm.vue'
import RadiusAvp from './RadiusAvp.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    KendoSwitch,
    ControlWrapper,
    ControlButton,
    GridWrapper,
    Grid,
    Dialog,
    NewRadiusClientForm,
    DynamicVlanForm,
    RadiusAvp
  },
  setup() {
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInRadiusSiteConfig()

    onMounted(() => {
      dataItems.value = createRandomData(32) as never[]
    })

    const {
      show: showNewRadiusClient,
      toggle: toggleNewRadiusClient
    } = useToggle()
    const { show: showDynamicVlan, toggle: toggleDynamicVlan } = useToggle()
    const { show: showRadiusAvp, toggle: toggleRadiusAvp } = useToggle()

    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      // Dialog 相關
      showNewRadiusClient,
      toggleNewRadiusClient,
      showDynamicVlan,
      toggleDynamicVlan,
      showRadiusAvp,
      toggleRadiusAvp
    }
  }
})
