
import { defineComponent, onMounted } from 'vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInRadiusAvp from '@/composable/useGridInRadiusAvp'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewRadiusAvpForm from '@/components/Form/RadiusSiteConfigView/NewRadiusAvpForm.vue'

export default defineComponent({
  components: {
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    GridWrapper,
    GridPageInfo,
    Grid,
    Dialog,
    NewRadiusAvpForm
  },
  setup() {
    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInRadiusAvp()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    const { show: showNewRadiusAvp, toggle: toggleNewRadiusAvp } = useToggle()
    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      showNewRadiusAvp,
      toggleNewRadiusAvp
    }
  }
})
