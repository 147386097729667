<template>
  <label class="base-checkbox" :class="{ 'is-disabled': disabled }">
    <input
      type="checkbox"
      :checked="modelValue"
      @change="updateInput"
      :disabled="disabled"
    />
    <div class="custom-checkbox"></div>
    <!-- 自訂 checkbox label 內容 -->
    <slot />
    <!--  -->
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// v-model on the custom component
// CHECK: (https://v3.vuejs.org/guide/migration/v-model.html#_3-x-syntax)

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: Boolean
  },
  setup(props, ctx) {
    const updateInput = () => {
      ctx.emit('update:modelValue', !props.modelValue)
    }
    return {
      updateInput
    }
  }
})
</script>

<style scoped></style>
