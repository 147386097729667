<template>
  <div class="top-bar">
    <router-link class="back-link" :to="backTo">
      <SvgIcon icon="arrow-left" />
    </router-link>
    <h2 class="d-inline"><slot /></h2>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    backTo: Object
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
