
import { defineComponent, reactive } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList,
    BaseCheckbox
  },
  setup() {
    const settings = reactive({
      innerDefaultVlan: 21,
      outerDefaultVlan: 22,
      autoInnerDefaultVlan: 3,
      autoOuterDefaultVlan: 4,
      autoInner: true
    })
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      settings,
      submitForm
    }
  }
})
