<template>
  <div class="form-wrapper">
    <form>
      <div class="d-flex flex-row">
        <!-- 左側：輸入欄 -->
        <div class="left-column" style="width: 380px;">
          <div class="single-feild-group pl-1">
            <label class="feild-name" style="min-width: 89px;">IP 位置</label>
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <KendoInput :type="'text'" style="width: 215px;" />
            </FeildInputGroup>
          </div>
          <div class="single-feild-group pl-1">
            <label class="feild-name" style="min-width: 89px;">首碼長度</label>
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <KendoInput :type="'text'" style="width: 215px;" />
            </FeildInputGroup>
          </div>
          <div class="single-feild-group pl-1">
            <label class="feild-name" style="min-width: 89px;">密碼</label>
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <KendoInput :type="'password'" style="width: 215px;" />
            </FeildInputGroup>
          </div>
          <div class="single-feild-group pl-1" style="marginBottom: 72px;">
            <label class="feild-name" style="min-width: 89px;">密碼</label>
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <DropDownList
                style="width: 215px;"
                :data-source="[
                  { name: 'Dynamic VLAN', value: 'Dynamic' },
                  { name: '802.1x auth', value: '802.1' }
                ]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
            </FeildInputGroup>
          </div>
        </div>
        <div class="vl-divider" style="height: 270px;"></div>
        <!-- 右側：表格 -->
        <div class="right-column text-center flex-grow-1">
          <h5 class="mt-2">Dynamic VLAN</h5>
          <p class="text-gray-400 mb-3">啟用 Reject 未授權 MAC : 否</p>
          <div class="px-4 mb-5">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th class="text-left border-0 font-weight-normal">
                    Attribute Type
                  </th>
                  <th class="border-0 font-weight-normal">Value Type</th>
                  <th class="border-0 font-weight-normal">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-left border-0 font-weight-normal">
                    TUNNEL_MEDIUM_TYPE
                  </th>
                  <td class="border-0 font-weight-normal">Number</td>
                  <td class="border-0 font-weight-normal">6</td>
                </tr>
                <tr>
                  <th class="text-left font-weight-normal">
                    TUNNEL_PRIVATE_GROUP_ID
                  </th>
                  <td>PIXIS_Setting</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th class="text-left font-weight-normal">
                    TUNNEL_PRIVATE_GROUP_ID
                  </th>
                  <td>Number</td>
                  <td>13</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成新增
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList
  },
  setup() {
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      submitForm
    }
  }
})
</script>

<style scoped></style>
