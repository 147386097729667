<template>
  <div id="view-setting">
    <Topbar :backTo="{ name: 'Site' }">Taipei Office 區域設定</Topbar>
    <div class="content-container">
      <SideNav>
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditGeneral', params: $route.params.id }"
          >屬性設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'GroupSetting', params: $route.params.id }"
          >網段群組設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'DHCPList', params: $route.params.id }"
          >註冊的 IP 派發來源</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditBlockMessage', params: $route.params.id }"
          >封鎖訊息 &amp; 驗證方式</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'ADAutoRegisterSetting', params: $route.params.id }"
          >AD 自動授權設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'RadiusSiteConfigSetting', params: $route.params.id }"
          >802.1x 設定</router-link
        >
      </SideNav>
      <div class="setting-container">
        <!-- 標題 -->
        <div class="title-row border-0">
          <h3 style="font-size: 22px;">802.1x 設定</h3>
        </div>
        <div class="d-flex">
          <!-- 啟用開關 -->
          <div style="max-width: 255px; min-width: 255px; margin-right: 44px;">
            <div class="feild-row" style="border-top: 1px solid #393D46;">
              <p class="feild-name" style="min-width: 194px;">啟用 802.1x</p>
              <p class="feild-value">
                <KendoSwitch :checked="true"></KendoSwitch>
              </p>
            </div>
            <div class="feild-row" style="border-bottom: 1px solid #393D46;">
              <p class="feild-name" style="min-width: 194px;">
                啟用動態接入 VLAN
              </p>
              <p class="feild-value">
                <KendoSwitch :checked="true"></KendoSwitch>
              </p>
            </div>
          </div>
          <div class="flex-grow-1" style="min-width: 630px;">
            <!-- 右側：列表容器 -->
            <div class="main-list-view setting-list-view darken-list">
              <div class="main-list-container">
                <div class="title-wrapper">
                  <h2 class="title" style="font-size: 18px;">
                    Radius Clint 列表
                  </h2>
                </div>
                <!-- 上：控制列容器 -->
                <ControlWrapper style="border-top: 1px solid #393D46;">
                  <ControlButton
                    iconName="plus"
                    text="新增 Radius Client"
                    @click="toggleNewRadiusClient"
                  />
                  <ControlButton
                    iconName="setup"
                    text="設定 Radius AVP"
                    @click="toggleRadiusAvp"
                  />
                </ControlWrapper>
                <!-- 下：表格容器 -->
                <GridWrapper>
                  <Grid
                    :style="{ height: 'calc(100vh - 350px)' }"
                    :columns="columns"
                    :data-items="result"
                    :pageable="pageable"
                    :skip="skip"
                    :take="take"
                    :total="total"
                    @pagechange="pageChangeHandler"
                    :scrollable="'true'"
                    :sortable="false"
                    @toggleEditGroup="toggleEditGroup"
                  />
                </GridWrapper>
              </div>
            </div>
            <!-- 下：驗證方式 -->
            <div
              class="title-row align-items-center mt-5"
              style="border-color: #393D46;"
            >
              <h4 class="my-0" style="font-size: 18px;">動態接入 VLAN</h4>
            </div>
            <div style="background-color: #1B1E23;">
              <div class="feild-row feild-row-sm">
                <p class="feild-name pl-4">
                  內部設備對應
                </p>
                <p class="feild-value text-right pr-4" style="width: 100%;">
                  <a
                    href="#"
                    style="text-decoration: underline; color: #2F9471;"
                    >VLAN 對應表</a
                  >
                </p>
              </div>
              <div class="feild-row feild-row-sm">
                <p class="feild-name pl-4">
                  VLAN 對應來源
                </p>
                <p class="feild-value text-right pr-4" style="width: 100%;">
                  裝置最後上面的 VLAN
                </p>
              </div>
              <div class="feild-row feild-row-sm">
                <p class="feild-name pl-4">
                  預設
                </p>
                <p class="feild-value text-right pr-4" style="width: 100%;">
                  內部預設 VLAN 21<br />外部預設 VLAN 22
                </p>
              </div>
              <div class="feild-row feild-row-sm">
                <p class="feild-name pl-4">
                  自動隔離機制
                </p>
                <p class="feild-value text-right pr-4" style="width: 100%;">
                  外部隔離 VLAN 4
                </p>
              </div>
              <div class="feild-row feild-row-sm border-0 mb-4">
                <p class="feild-name pl-4">
                  內部上線驗證帳號
                </p>
                <p class="feild-value text-right pr-4" style="width: 100%;">
                  關閉
                </p>
              </div>
            </div>
            <button
              class="btn form-btn-outline mr-2 px-3 mb-5"
              @click="toggleDynamicVlan"
            >
              <SvgIcon icon="setup" />
              設定動態接入 VLAN
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <Dialog
      v-if="showNewRadiusClient"
      :title="'新增 Radius Client'"
      :width="930"
      :height="580"
      @close="toggleNewRadiusClient"
    >
      <NewRadiusClientForm @close="toggleNewRadiusClient" />
    </Dialog>
    <Dialog
      v-if="showDynamicVlan"
      :title="'設定動態接入 VLAN'"
      :width="930"
      :height="560"
      @close="toggleDynamicVlan"
    >
      <DynamicVlanForm @close="toggleDynamicVlan" />
    </Dialog>
    <Dialog
      v-if="showRadiusAvp"
      class="full-dialog"
      :title="'設定 Radius AVP'"
      @close="toggleRadiusAvp"
    >
      <RadiusAvp />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInRadiusSiteConfig from '@/composable/useGridInRadiusSiteConfig'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewRadiusClientForm from '@/components/Form/RadiusSiteConfigView/NewRadiusClientForm.vue'
import DynamicVlanForm from '@/components/Form/RadiusSiteConfigView/DynamicVlanForm.vue'
import RadiusAvp from './RadiusAvp.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    KendoSwitch,
    ControlWrapper,
    ControlButton,
    GridWrapper,
    Grid,
    Dialog,
    NewRadiusClientForm,
    DynamicVlanForm,
    RadiusAvp
  },
  setup() {
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInRadiusSiteConfig()

    onMounted(() => {
      dataItems.value = createRandomData(32) as never[]
    })

    const {
      show: showNewRadiusClient,
      toggle: toggleNewRadiusClient
    } = useToggle()
    const { show: showDynamicVlan, toggle: toggleDynamicVlan } = useToggle()
    const { show: showRadiusAvp, toggle: toggleRadiusAvp } = useToggle()

    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      // Dialog 相關
      showNewRadiusClient,
      toggleNewRadiusClient,
      showDynamicVlan,
      toggleDynamicVlan,
      showRadiusAvp,
      toggleRadiusAvp
    }
  }
})
</script>

<style scoped></style>
