<template>
  <td class="type-setting-cell" :data-id="dataItem.Id">
    <div
      v-for="(item, idx) in dataItem.TypeSetting"
      :key="idx"
      style="margin: 4px 0;"
      class="text-success"
    >
      <span class="text-warning">{{ item.attributeType }}</span> -
      <span class="text-primary">{{ item.valueType }}</span> -
      <span class="text-success">{{ item.value }}</span>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
