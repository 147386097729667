<template>
  <div class="form-wrapper bg-transparent">
    <form>
      <div class="single-feild-group pl-2 mb-2">
        <label class="feild-name">預設</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'" class="mb-2">
          <KendoInput :type="'text'" style="width: 280px;" />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group pl-2">
        <label class="feild-name">預設</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'" class="mb-2">
          <DropDownList
            style="width: 280px;"
            :data-source="[
              { name: '- 請選擇 AVP 範本 -', value: 0 },
              { name: '動態接入 VLAN', value: 1 }
            ]"
            :data-text-field="'name'"
            :data-value-field="'value'"
          />
        </FeildInputGroup>
        <FeildInputGroup
          :isInvalid="false"
          :errorMsg="'輸入錯誤'"
          class="mb-2 ml-5"
        >
          <BaseCheckbox
            class="form-checkbox font-weight-normal"
            v-model="newRediusAvp.enableRejectUnauthMac"
            >啟用 Reject 未授權 MAC</BaseCheckbox
          >
        </FeildInputGroup>
      </div>
      <div class="dragable-list">
        <div class="dragable-item">
          <SvgIcon icon="drag-handle" />
          <div class="single-feild-group pl-2 attr-type-feild">
            <label class="feild-name">Attribute Type</label>
            <DropDownList
              style="width: 250px;"
              :data-source="[
                { name: 'TUNNEL_MEDIUM_TYPE', value: 'TUNNEL_MEDIUM_TYPE' },
                {
                  name: 'TUNNEL_PRIVATE_GROUP_ID',
                  value: 'TUNNEL_PRIVATE_GROUP_ID'
                }
              ]"
              :data-text-field="'name'"
              :data-value-field="'value'"
            />
          </div>
          <div class="single-feild-group pl-2 value-type-feild">
            <label class="feild-name">Value Type</label>
            <DropDownList
              :data-source="[
                { name: 'Number', value: 'Number' },
                {
                  name: 'PIXIS_Setting',
                  value: 'PIXIS_Setting'
                }
              ]"
              :data-text-field="'name'"
              :data-value-field="'value'"
            />
          </div>
          <div class="single-feild-group pl-2 value-feild">
            <label class="feild-name">Value</label>
            <KendoInput :type="'text'" style="width: 68px;" :value="1" />
          </div>
          <SvgIcon icon="garbage" />
        </div>
        <div class="dragable-item">
          <SvgIcon icon="drag-handle" />
          <div class="single-feild-group pl-2 attr-type-feild">
            <label class="feild-name">Attribute Type</label>
            <DropDownList
              style="width: 250px;"
              :data-source="[
                { name: 'TUNNEL_MEDIUM_TYPE', value: 'TUNNEL_MEDIUM_TYPE' },
                {
                  name: 'TUNNEL_PRIVATE_GROUP_ID',
                  value: 'TUNNEL_PRIVATE_GROUP_ID'
                }
              ]"
              :data-text-field="'name'"
              :data-value-field="'value'"
            />
          </div>
          <div class="single-feild-group pl-2 value-type-feild">
            <label class="feild-name">Value Type</label>
            <DropDownList
              :data-source="[
                { name: 'Number', value: 'Number' },
                {
                  name: 'PIXIS_Setting',
                  value: 'PIXIS_Setting'
                }
              ]"
              :data-text-field="'name'"
              :data-value-field="'value'"
            />
          </div>
          <div class="single-feild-group pl-2 value-feild">
            <label class="feild-name">Value</label>
            <KendoInput :type="'text'" style="width: 68px;" :value="6" />
          </div>
          <SvgIcon icon="garbage" />
        </div>
        <div class="add-btn"><SvgIcon icon="plus" />新增指令</div>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成新增
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList,
    BaseCheckbox
  },
  setup() {
    const newRediusAvp = reactive({
      enableRejectUnauthMac: true
    })
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      newRediusAvp,
      submitForm
    }
  }
})
</script>

<style scoped></style>
