<template>
  <div class="form-wrapper">
    <form>
      <div class="d-flex flex-row">
        <!-- 左側：輸入欄 -->
        <div class="left-column" style="width: 430px;">
          <div class="single-feild-group pl-1">
            <label class="feild-name" style="min-width: 130px;"
              >內部設備對應</label
            >
            <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
              <DropDownList
                style="width: 215px;"
                :data-source="[
                  { name: '授權狀態', value: 0 },
                  { name: 'VLAN 對應表', value: 1 }
                ]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
            </FeildInputGroup>
          </div>
          <div class="single-feild-group pl-1 mb-3">
            <label class="feild-name" style="min-width: 130px;"
              >VLAN 對應來源</label
            >
            <div>
              <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
                <DropDownList
                  style="width: 215px;"
                  :data-source="[
                    { name: '裝置最後上線 VLAN', value: 0 },
                    { name: 'VLAN 對應表', value: 1 }
                  ]"
                  :data-text-field="'name'"
                  :data-value-field="'value'"
                />
              </FeildInputGroup>
              <div class="text-primary mt-2 ml-2">
                VLAN 對應表
                <a
                  href="#"
                  class="text-primary"
                  style="text-decoration: underline;"
                  >查看</a
                >
              </div>
            </div>
          </div>
          <div class="single-feild-group pl-1">
            <label class="feild-name" style="min-width: 130px;">預設</label>
            <div>
              <FeildInputGroup
                :isInvalid="false"
                :errorMsg="'輸入錯誤'"
                class="mb-2"
              >
                <span class="mr-3 text-in-form font-weight-normal"
                  >內部預設 VLAN</span
                >
                <KendoInput
                  :type="'text'"
                  style="width: 78px;"
                  v-model="settings.innerDefaultVlan"
                />
              </FeildInputGroup>
              <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
                <span class="mr-3 text-in-form font-weight-normal"
                  >外部預設 VLAN</span
                >
                <KendoInput
                  :type="'text'"
                  style="width: 78px;"
                  v-model="settings.outerDefaultVlan"
                />
              </FeildInputGroup>
            </div>
          </div>
        </div>
        <div class="vl-divider mb-4" style="height: 250px;"></div>
        <!-- 右側：表格 -->
        <div class="right-column flex-grow-1">
          <div class="single-feild-group flex-column pl-5 mb-3">
            <label class="feild-name text-left">自動隔離</label>
            <div>
              <div class="mb-2">
                <BaseCheckbox
                  class="form-checkbox font-weight-normal"
                  v-model="settings.autoInner"
                >
                  內部自動隔離
                  <span class="mr-3 ml-4 text-in-form font-weight-normal"
                    >內部預設 VLAN</span
                  >
                  <KendoInput
                    :type="'text'"
                    style="width: 78px;"
                    v-model="settings.autoInnerDefaultVlan"
                  />
                </BaseCheckbox>
              </div>
              <div>
                <BaseCheckbox
                  class="form-checkbox font-weight-normal"
                  :disabled="true"
                >
                  外部自動隔離
                  <span class="mr-3 ml-4 text-in-form font-weight-normal"
                    >外部預設 VLAN</span
                  >
                  <KendoInput
                    :type="'text'"
                    style="width: 78px;"
                    v-model="settings.autoOuterDefaultVlan"
                  />
                </BaseCheckbox>
              </div>
            </div>
          </div>
          <div class="single-feild-group flex-column pl-5">
            <label class="feild-name text-left"
              >線上驗證帳號
              <span class="text-info font-weight-normal ml-2">
                <SvgIcon icon="warning" />
                需啟用 [<a
                  href="#"
                  class="text-info"
                  style="text-decoration: underline;"
                  >封鎖訊息</a
                >] 即 [<a
                  href="#"
                  class="text-info"
                  style="text-decoration: underline;"
                  >驗證方式</a
                >]
              </span>
            </label>
            <div>
              <div class="mb-2">
                <BaseCheckbox
                  class="form-checkbox font-weight-normal"
                  :disabled="true"
                >
                  內部上線驗證帳號
                </BaseCheckbox>
              </div>
              <div>
                <BaseCheckbox
                  class="form-checkbox font-weight-normal"
                  :disabled="true"
                >
                  外部上線驗證帳號
                </BaseCheckbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成新增
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList,
    BaseCheckbox
  },
  setup() {
    const settings = reactive({
      innerDefaultVlan: 21,
      outerDefaultVlan: 22,
      autoInnerDefaultVlan: 3,
      autoOuterDefaultVlan: 4,
      autoInner: true
    })
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      settings,
      submitForm
    }
  }
})
</script>

<style scoped></style>
