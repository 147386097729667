import { ref, computed, markRaw } from 'vue'

// Cells
import IP from '../components/Grid/Cell/DHCPListView/IP.vue'
import Operation from '../components/Grid/Cell/DHCPListView/Operation.vue'
import OperationHeader from '../components/Grid/Cell/DHCPListView/OperationHeader.vue'
import PrefixLength from '../components/Grid/Cell/RadiusSiteConfigView/PrefixLength.vue'
import PrefixLengthHeader from '../components/Grid/Cell/RadiusSiteConfigView/PrefixLengthHeader.vue'

// 區域設定/Radius Clint 列表中使用 Kendo Grid
const useGridInRadiusSiteConfig = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)

  // Header 欄位
  const columns = ref([
    {
      field: 'IP',
      title: 'IP',
      width: '150px',
      cell: markRaw(IP)
    },
    {
      field: 'Operation',
      title: '操作',
      width: '120px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'PrefixLength',
      title: '首碼長度',
      width: '120px',
      cell: markRaw(PrefixLength),
      headerCell: markRaw(PrefixLengthHeader)
    },
    {
      field: 'RadiusAVP',
      title: 'Radius AVP',
      width: 'auto'
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return dataItemsWithPage
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const operation = [{ isDeletable: true }]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        IP: '192.168.187.111',
        Operation: operation[Math.floor(Math.random() * operation.length)],
        PrefixLength: '2',
        RadiusAVP: 'Dynamic VLAN'
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    createRandomData,
    pageChangeHandler
  }
}

export default useGridInRadiusSiteConfig
