<template>
  <td class="host-mac-cell" :data-id="dataItem.Id">
    <div
      v-if="dataItem.IP"
      class="copy-wrapper"
      v-clipboard="dataItem.IP"
      v-clipboard:success="
        () => this.$toast.show('複製成功 ' + dataItem.IP, 'success')
      "
    >
      <KendoTooltip id="tooltip" title="點擊複製" :position="'right'">
        <span>{{ dataItem.IP }}</span>
        <SvgIcon icon="copy" class="copy-icon" />
      </KendoTooltip>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
