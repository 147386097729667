<template>
  <div class="radius-avp-wrapper main-list-view">
    <div class="radius-avp-list-container darken-list">
      <!-- 上：控制列容器 -->
      <ControlWrapper>
        <ControlButton
          iconName="plus"
          text="新增 Radius AVP"
          @click="toggleNewRadiusAvp"
        />
        <ControlButton iconName="copy" text="建立副本" :disabled="true" />
      </ControlWrapper>

      <!-- 中：篩選列容器 -->
      <FilterWrapper>
        <FilterTextInput
          :placeholder="'查詢名稱'"
          v-model.trim="textInput"
          @submitFilter="submitFilter"
        />
      </FilterWrapper>

      <!-- 下：表格容器 -->
      <GridWrapper>
        <GridPageInfo :skip="skip" :take="take" :total="total" />
        <Grid
          :style="{ height: 'calc(100vh - 290px)' }"
          :columns="columns"
          :data-items="result"
          :pageable="pageable"
          :skip="skip"
          :take="take"
          :total="total"
          @pagechange="pageChangeHandler"
          :scrollable="'true'"
          :sortable="false"
        />
      </GridWrapper>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <Dialog
      v-if="showNewRadiusAvp"
      class="full-dialog is-nested"
      :title="'新增 Radius AVP'"
      @close="toggleNewRadiusAvp"
    >
      <NewRadiusAvpForm @close="toggleNewRadiusAvp" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInRadiusAvp from '@/composable/useGridInRadiusAvp'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewRadiusAvpForm from '@/components/Form/RadiusSiteConfigView/NewRadiusAvpForm.vue'

export default defineComponent({
  components: {
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    GridWrapper,
    GridPageInfo,
    Grid,
    Dialog,
    NewRadiusAvpForm
  },
  setup() {
    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInRadiusAvp()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    const { show: showNewRadiusAvp, toggle: toggleNewRadiusAvp } = useToggle()
    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      showNewRadiusAvp,
      toggleNewRadiusAvp
    }
  }
})
</script>

<style scoped></style>
